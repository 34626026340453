<template>
  <div id="assignment-upload-page" class="position-relative">

    <!-- Header -->
    <div class="page-header upload-assignment-header row w-100 align-items-end mx-0 mb-4">
      <div class="title col-12 col-md-8 mx-auto pb-2">
        <h3 class="text-white">{{ $t("telestia.title.assignments") }}</h3>
      </div>
    </div>

    <!-- Loading state -->
    <div v-if="pendingComponentInit" class="loading-state d-flex justify-content-center">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" variant="primary" />
    </div>

    <!-- Loaded state -->
    <template v-else>
      <div class="row mx-0">
        <div class="col-12 col-md-8 mx-md-auto">

          <!-- Error -->
          <template v-if="error">
            <h4 class="mb-2">{{ error }}</h4>
          </template>

          <!-- Listing -->
          <template v-else>

            <!-- Course title & Guidelines -->
            <div class="course-title guidelines d-flex">
              <h5>{{ currentCourseTitle }}</h5>
              <div class="ml-auto text-right">
                <span v-b-modal.guidelines-modal class="text-link text-underline mouse-pointer">{{ $t("telestia.title.attachment_guidelines") }}</span>
                <br>
                <span>{{ $t("telestia.title.maximum_size") }}: <b>2MB</b></span>
              </div>
            </div>

            <!-- Back button -->
            <button class="back-button primary-btn btn-sm mt-3" @click="$router.go(-1)">
              <font-awesome-icon :icon="['fas', 'reply']" class="mr-2"/>{{ $t("telestia.button.back") }}
            </button>

            <!-- Lesson listing -->
            <div class="lessons-listing row my-4">
              <div class="col">
                <div class="table-responsive">
                  <table class="table align-middle">
                    <thead>
                      <tr>
                        <th>{{ $t("telestia.table.header.package") }}<br>{{ $t("telestia.table.header.lesson") }}</th>
                        <th class="text-center">{{ $t("telestia.table.header.studied_on") }}</th>
                        <th class="text-center">
                          {{ $t("telestia.table.header.uploaded_assignments") }}
                          <span  v-b-modal.marksguide-modal class="marks-icon mouse-pointer ml-2">?</span>
                        </th>
                        <th class="text-center">{{ $t("telestia.table.header.upload_new") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(lesson, lIndex) in lessons" :key="`${lesson.code}-${lIndex}`" :class="{ 'next-class': currentLessonKey === lesson.code }">
                        <td>
                          <span style="color:gray">{{ lesson.package }}</span><br>
                          <span
                              class="text-link mouse-pointer text-underline"
                              @click="selectLesson({ menu1Index: lesson.menu1.index, menu2Index: lesson.menu2.index, lessonKey: lesson.code }, lesson.id)">
                            {{ lesson.title }}
                          </span>
                        </td>
                        <td class="text-center">
                          <span v-if="lesson.studiedOn">{{ lesson.studiedOn | formatDate }}</span>
                          <span v-else>--</span>
                        </td>
                        <td class="text-center">
                          <span v-if="lesson.uploads.length === 0">--</span>
                          <div v-else class="d-flex align-items-center" v-for="(upload, index) in lesson.uploads" :key="`${upload.timestamp}-${index}`">
                            <div class="upload-container position-relative" @mouseenter="fetchAssignment(lesson.id, upload.id)">
                              <font-awesome-icon
                                :icon="['fas', getIconBasedOnSender(upload.type)]"
                                class="upload-icon"
                                :class="{ 'mouse-pointer': !upload.pendingDataFetch }"
                                :style="{ color: getColorBasedOnSender(upload.type) }"
                                @click="downloadAssignment(upload.data, upload.extension, lesson.title, index+1)"
                              />

                              <div v-if="['png', 'jpeg', 'jpg'].includes(upload.extension)" class="upload-tooltip position-absolute px-2 py-1 shadow">
                                <b-spinner v-if="upload.pendingDataFetch" style="width: 1rem; height: 1rem;" type="grow" variant="primary" />
                                <img v-else class="mt-1 mb-1" :src="bufferToImageBase64(upload.data.data, upload.extension)" alt="upload-image"/>
                              </div>
                            </div>

                            <span class="upload-date ml-2">({{ index + 1 }}) {{ upload.date | formatDate }}</span>
                            <span v-if="upload.rate" class="rate-badge ml-2" :style="badgeStyleByRate(upload.rate)">{{ upload.rate }}</span>
                          </div>
                        </td>
                        <td class="text-center">
                          <div class="col-2 d-flex align-items-center">
                            <input
                              type="file"
                              name="assignment"
                              :id="`assignment-input-${lesson.code.toLowerCase()}`"
                              class="d-none"
                              accept="image/*, .pdf, .tcd"
                              @change="uploadAssignment($event, lesson.id, lesson.title)"
                              :disabled='!isCourseActive'
                            />
                            <button
                              v-if="lesson.studiedOn"
                              class="file-upload primary-btn btn-sm" :class="{ disabled: !isCourseActive }"
                              @click="fireFileInput(lesson.code)"
                            >
                              {{ $t("telestia.button.upload") }}
                            </button>
                          </div>
                        </td>
                      </tr>
                      
                      <template v-if="!isOfflineBuild">
                        <tr v-for="(link, title) in orgAssignments" :key="title">
                          <td>
                            <span style="color:gray">{{ currentCourseTitle }}</span><br>
                            <a :href="`${link}?lang=${selectedLanguage}`" target="_blank" class="text-link mouse-pointer text-underline">{{ $t(title) }}</a>
                          </td>
                          <td class="text-center"><span>--</span></td>
                          <td class="text-center"><span>--</span></td>
                          <td class="text-center">
                            <div class="col-2 d-flex align-items-center">
                              <a v-if="completedCourse" :href="`${link}?lang=${selectedLanguage}`" target="_blank" class="file-upload primary-btn btn-sm">
                                {{ $t("telestia.button.open") }}
                              </a>
                            </div>
                          </td>
                        </tr>
                      </template>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>

          <!-- Back button -->
          <button class="back-button primary-btn btn-sm mb-3" @click="$router.go(-1)">
            <font-awesome-icon :icon="['fas', 'reply']" class="mr-2"/>{{ $t("telestia.button.back") }}
          </button>

        </div>
      </div>
    </template>

    <b-modal id="guidelines-modal" centered scrollable hide-header hide-footer size="lg" hide-header-close>
      <template>
        <div class="d-flex align-items-center">
          <h4 class="mb-0">{{ $t('telestia.title.photo_guidelines') }}</h4>
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="ml-auto mouse-pointer"
            @click="$bvModal.hide('guidelines-modal')"
          />
        </div>
        <hr>
        <div class="d-block" v-html="translatedGuidelines"></div>
      </template>
    </b-modal>

    <b-modal id="marksguide-modal" centered scrollable hide-footer hide-header hide-header-close size="lg">
      <template>
        <div class="d-flex align-items-center">
          <h4 class="mb-0">{{ $t('telestia.title.marks_guide') }}</h4>
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="ml-auto mouse-pointer"
            @click="$bvModal.hide('marksguide-modal')"
          />
        </div>
        <hr>
        <div class="d-block" v-html="translatedMarksGuide"></div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { bufferDataToBlob } from '../../shared/helpers/file.js';
import _isEmpty from 'lodash/isEmpty';
import _orderBy from 'lodash/orderBy';
import _kebabCase from 'lodash/kebabCase';
import uploadGuidelines from '../shared/uploadGuidelines.js';
import marksGuide from '../shared/marksGuide.js';

export default {
  name: "assignment-upload",

  data() {
    return {
      pendingComponentInit: false,

      currentCourseTitle: '',
      lessons: [],

      uploadGuidelines,
      marksGuide,
      error: ''
    }
  },

  created() {
    this.fetchLessonsWithAssignments();
  },

  computed: {
    isOnlineBuild() { return this.$store.getters["app/isOnline"]; },
    isOfflineBuild() { return this.$store.getters['app/isOffline'] },

    selectedLanguage() { return this.$store.getters['user/getSelectedLanguage']; },

    currentLessonKey() {
      const currentLessonKey = this.$store.getters.getSelected.lessonKey;
      if (!currentLessonKey) { return ''; }

      const previousLesson = this.$store.getters.getPreviouslyViewedLesson;
      return this.lessons.findIndex(l => l.code === currentLessonKey) > -1
        ? currentLessonKey
        : previousLesson.hasAssignment ? previousLesson.key : ''
    },

    currentCourseId() { return this.$store.getters["userCourses/getCourseId"](this.$store.getters.getSelected.course) },

    isCourseActive() { return !this.$store.getters['userCourses/isCourseExpired'](this.$store.getters.getSelected.course) },

    orgAssignments() { return (this.$store.getters['userOrganisation/getOrganisation']).assignments },

    completedCourse() { return (this.$store.getters['progress/getCurrentCourseState']).finished },

    // Assignment upload guidlines based on selected language if present
    translatedGuidelines() {
      return this.uploadGuidelines[this.selectedLanguage]
          ? this.uploadGuidelines[this.selectedLanguage]
          : this.uploadGuidelines.en;
    },

    translatedMarksGuide() {
      return this.marksGuide[this.selectedLanguage] ?? this.marksGuide.en;
    }

  },

  methods: {
    // Fetch uploaded assignments of selected course
    async fetchLessonsWithAssignments() {
      try {
        this.pendingComponentInit = true;

        this.currentCourseTitle = await this.getCurrentCourseTitle();
        let lessons = await this.$store.dispatch("userAssignments/fetchUploadedAssignmentsByCourseId", this.currentCourseId);
        lessons = lessons.map(l => {
          l.assignments = l.assignments.map(a => { return { ...a, pendingDataFetch: true, rate: this.mapRate(a.rate) }; });

          const lessonCode = this.$store.getters.getLessonCode(l.lessonId);
          const menuInfo = this.$store.getters.getLessonMenuEntries(lessonCode);
          return {
            ...menuInfo,
            title: l.title || menuInfo.title,
            id: l.lessonId,
            package: l.packageTitle,
            studiedOn: l.studiedOn,
            uploads: _orderBy(l.assignments, ['date'], ['asc'])
          }
        })
        lessons = lessons.filter(l => l.code && l.title);
        this.lessons = lessons;

        await this.fetchProgressForSelectedCourse(this.currentCourseId);

      } catch (e) {
        console.log(e);
        this.error = this.$t('telestia.error.assignment') + ' ' + this.$t('telestia.error.connection');
      } finally {
        this.pendingComponentInit = false;

        // Scroll to current lesson
        this.$nextTick(() => {
          let el = document.querySelector(`tr[class~="next-class"]`);
          if (el) el.scrollIntoView({ block: 'center', behavior: 'smooth' });
        });
      }
    },

    // Returns current course title
    async getCurrentCourseTitle() {
      let currentCourseTitle = ''
      if (!this.isOnlineBuild) {
        try {
          const products = await this.$store.dispatch('getProductCourses')
          products.forEach(prod => {
            const selectedCourse = prod.courses.find(c => c.ckey === this.$store.getters.getSelected.course)
            if (selectedCourse) {
              currentCourseTitle = selectedCourse.ctitle
            }
          })
          return Promise.resolve(currentCourseTitle);
        } catch (e) {
          return Promise.reject(e);
        }
      }

      const currentCourseKey = this.$store.getters.getSelected.course;
      currentCourseTitle = this.$store.getters.getCourseTitle(currentCourseKey);
      return Promise.resolve(currentCourseTitle);
    },

    // Fetch progress for selected course (to be used in order to restrict access to a lesson if needed);
    async fetchProgressForSelectedCourse(currentCourseId) {
      try {
        await this.$store.dispatch("progress/fetchLastNextToStudy", currentCourseId);
        return Promise.resolve();
      } catch (e) {
        console.log(e);
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.course_progress') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
        return Promise.resolve();
      }
    },

    // Fetch assignment from file server
    async fetchAssignment(lessonId, uploadId) {
      const upload = this.lessons.find(l => l.id === lessonId).uploads.find(u => u.id === uploadId);
      if (upload.data) { return; }

      try {
        const upld = await this.$store.dispatch('userAssignments/fetchUserAssignmentById', uploadId);
        upload.data = upld.data;
      } catch (e) {
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.assignment') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
      } finally {
        upload.pendingDataFetch = false;
      }
    },

    // Upload assignment functionality
    async uploadAssignment(e, lessonId, lessonTitle) {
      const fileToUpload = e.target.files[0];
      if (Math.round((fileToUpload.size / 1024)) > 2048) {
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.attachment_too_big') + "\n" + this.$t('telestia.error.connection'),
          icon: 'error',
        });
        return;
      }

      try {
        const uploadFormData = new FormData();
        uploadFormData.append('course_id', this.currentCourseId);
        uploadFormData.append('lesson_title', lessonTitle);
        uploadFormData.append('lesson_id', lessonId);
        uploadFormData.append('attachment', fileToUpload);

        const newUpload = await this.$store.dispatch("userAssignments/uploadUserAssignment", uploadFormData);

        const relatedLesson = this.lessons.find(l => l.id === lessonId);
        relatedLesson.uploads.push(newUpload);
        relatedLesson.uploads = _orderBy(relatedLesson.uploads, ['date'], ['desc']);

        this.showAlert({
          title: this.$t('telestia.assignments.uploaded'),
          text: '',
          icon: 'success',
        });
      } catch(e) {
        this.showAlert({
          title: this.$t('telestia.error.title'),
          text: this.$t('telestia.error.assignment_upload'),
          icon: 'error',
        });
      }
    },

    // Helper function to download an uploaded assignment
    downloadAssignment(data, extension, title, num) {
    	if (!data || !extension) { return; }

    	const elem = document.createElement('a');
		  elem.href = this.createHref(data.data, extension);
      elem.download = _kebabCase(`${this.$store.getters['user/getFirstName']} ${this.$store.getters['user/getLastName']} ${title}-${num}`) + `.${extension}`;
		  document.body.appendChild(elem);
		  elem.click();
		  document.body.removeChild(elem);
    },

    // Method that maps numeric rate to string rate
    mapRate(rate) {
      switch (rate) {
        case 5: return 'A';
        case 4: return 'B';
        case 3: return 'C';
        case 2: return 'D';
        case 1: return 'E';
        default: return '';
      }
    },

    // Method that returns a style object based on an uploaded assignment rate
    badgeStyleByRate(rate) {
      switch (rate.toLowerCase()) {
        case 'a':
          return { backgroundColor: 'green', color: 'white' };
        case 'b':
          return { backgroundColor: 'yellowgreen', color: 'black' };
        case 'c':
          return { backgroundColor: 'yellow', color: 'black' };
        case 'd':
          return { backgroundColor: 'orange', color: 'black' };
        default:
          return { backgroundColor: 'red', color: 'white' };
      }
    },

    // ligthblue if uploaded by a user, lightgreen if uploaded by tutor
    getColorBasedOnSender(uploadType) { return uploadType === 0 ? 'lightskyblue' : 'red' },

    getIconBasedOnSender(uploadType) { return uploadType === 0 ? 'user' : 'graduation-cap' },

    // Helper function to convert a buffer to a base64 encoded image src
    bufferToImageBase64(data, imageType) {
      const base64String = new Buffer.from(data).toString("base64");
      return `data:image/${imageType};base64,${base64String}`;
    },

    createHref(data) { return URL.createObjectURL(bufferDataToBlob(data));	},

    // Helper function to download an uploaded assignment
    downloadBase64AssignmentFile(lessonCode, index) {
      const relatedLesson = this.lessons.find(l => l.code === lessonCode);
      const base64FileData = relatedLesson.uploads[index].data;
      const fileContentType = relatedLesson.uploads[index].type;
      const fileName = `${lessonCode.toLowerCase()}_upload_${index + 1}.${fileContentType.split('/')[1]}`

      const downloadLink = document.createElement('a');
      downloadLink.href = base64FileData;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
    },

    // Select lesson if user is allowed to view it
    selectLesson(items, lessonId) {
      if (!this.$store.getters.isAllowedToView(lessonId)) {
        const nextLessonTitle = this.$store.getters["progress/getNextToStudy"].menu.title;
        this.showAlert({
          title: '',
          text: this.$t('telestia.error.not_active') + `"${nextLessonTitle}"` + "\n" + this.$t('telestia.error.check_todaysclass'),
          icon: 'warning',
        });
        return;
      }

      this.selectMenu(items);
      const selected = this.$store.getters.getSelected;
      this.$router.push({ name: 'App:Lessons:Lesson', params: { product_key: selected.productKey.toLowerCase(), course_key: selected.course.toLowerCase(), lesson_id: lessonId } });
    },

    // items is an object with possible properties: menu1Index, menu2Index, lessonKey
    selectMenu(items) {
      const productSkeleton = this.$store.getters["products/getCurrentProductSkeleton"];
      const payload = _isEmpty(productSkeleton) ? items : { ...items, productSkeleton  };
      this.$store.commit('selectMenu', payload);
    },

    fireFileInput(lessonCode) {
      document.getElementById(`assignment-input-${lessonCode.toLowerCase()}`).click();
    },

    showAlert({ title, text, icon }) {
      this.$swal({ title, text, icon, timer: 5000, button: true });
    }
  },

  watch: {
    selectedLanguage() {
      this.lessons.forEach(l => {
        const lessonCode = this.$store.getters.getLessonCode(l.id);
        const menuInfo = this.$store.getters.getLessonMenuEntries(lessonCode);

        l.menu1.title = menuInfo.menu1.title || l.menu1.title;
        l.menu2.title = menuInfo.menu2.title || l.menu2.title;
        l.title = menuInfo.title || l.title;
      })
    }
  }
}
</script>

<style scoped>
.text-link {
  color: black;
}
.text-link:hover {
  color: #1b96cf;
}

.file-upload {
  text-decoration: none;
  white-space: nowrap;
}

.upload-assignment-header {
  background-image: url(../../assets/images/page-header/assignments.png);
}
.upload-tooltip {
  display: none;
  z-index: 999;
  top: 0;
  right: 20px;
  background-color: white;
  border: 1px solid #1b96cf;
  border-radius: 5px;
}
.upload-container:hover .upload-tooltip {
  display: block;
}
.upload-tooltip img {
  max-height: 400px;
  max-width: 300px;
  width: auto;
  height: auto;
}
.rate-badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  border: 1px solid black;
  border-radius: 100px;
}
.next-class {
  background-color: lightgreen;
}
.upload-container {
  background: white;
}

.upload-assignment.upload-user * {
  color: blue;
}
.upload-assignment.upload-tutor * {
  color: green;
}

.upload-date {
  white-space: nowrap;
}

.upload-icon {
  width: 18px;
  height: 18px;
}

.marks-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  color: darkolivegreen;
  font-weight: 900;
  text-align: center;
  background: greenyellow;
  border: 1px solid darkolivegreen;
  border-radius: 20px;
  font-size: 10px;
}
</style>

