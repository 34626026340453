
const marksGuide =  {
	en: `
		<p>You can use the "Upload" button to upload photos or files of your work for the following Assignments. You may upload more than one file per assignment if necessary.
		The submitted files will be evaluated by our tutor team and graded with a letter.</p>
		<ul>
			<li>A means the assignment is very good with none or almost no errors. In this case, the tutor usually does not upload any corrections.</li>
			<li>B means good. There are some errors but not critical. However, you should correct them and, unless otherwise instructed by the tutor, the correction should be noted for the next assignments.</li>
			<li>C means the errors are critical. They must be corrected and the assignment should be submitted again.</li>
			<li>D means the task was submitted incorrectly or was misunderstood and should therefore be re-done and re-submitted, possibly after consultation with the tutor.</li>
		</ul>
		<p>Correction files are marked red. You can download them, view our corrections and feedback, and correct them accordingly.</p>
		<p>If you accidentally uploaded the wrong file, please inform your tutor immediately and they will delete it.</p>
	`,
	de: `
		<p>Über den "Hochladen" Knopf kannst du Fotos, bzw. Dateien, deiner Arbeit für die folgenden LEK Kursaufgaben hochladen. Du darfst, wenn nötig, mehr als eine Datei pro Kursaufgabe hochladen.
		Die eingereichte Dateien werden von unserem Tutoren-Team ausgewertet und mit einem Buchstabe benotet. </p>
		<ul>
			<li>A bedeutet die Kursaufgabe ist sehr gut mit keinem oder fast keinem Fehler. In diesem Fall wird meistens keine Korrektur seitens der Tutorin hochgeladen.</li>
			<li>B bedeutet gut. Es gibt zwar Fehler aber nicht kritisch. Diese sollst du jedoch berichtigen und, wenn es nicht anders von der Tutorin verordnet wird, soll die Korrektur für die nächsten Kursaufgaben gemerkt werden.</li>
			<li>C bedeutet die Fehler sind kritisch. Sie müssen korrigiert werden und anschließend soll die Kursaufgabe noch mal eingereicht werden.</li>
			<li>D bedeutet die Kursaufgabe wurde komplett falsch eingereicht, bzw. missverstanden und muss, eventuell nach Absprache mit der Tutorin, komplett neu gemacht werden. </li>
		</ul>
		<p>Korrektur-Dateien werden mit einem roten Hut bezeichnet. Du kannst sie runterladen, unsere Korrekturen und Feedback dir anschauen und entsprechend berichtigen. </p>
		<p>Falls du aus Versehen eine falsche Datei hochgeladen hast, bitte informiere deine Tutorin umgehend und sie wird diese löschen.</p>
	`,
	fr: `
		<p>Vous pouvez utiliser le bouton "Télécharger" pour télécharger des photos ou des fichiers de votre travail pour les devoirs suivants. Vous pouvez télécharger plus d'un fichier par devoir si nécessaire.
		Les fichiers soumis seront évalués par notre équipe de tuteurs et notés avec une lettre.</p>
		<ul>
			<li>A signifie que le devoir est très bon sans ou presque pas d'erreur. Dans ce cas, le tuteur ne télécharge généralement aucune correction.</li>
			<li>B signifie bien. Il y a des erreurs mais pas critiques. Cependant, vous devez les corriger et, sauf indication contraire du tuteur, la correction doit être notée pour les prochains devoirs de cours.</li>
			<li>C signifie que les erreurs sont critiques. Ils doivent être corrigés, puis resoumettre le devoir.</li>
			<li>D signifie que le devoir de cours a été soumis de manière totalement incorrecte ou mal compris et doit être entièrement refait, éventuellement après consultation du tuteur.</li>
		</ul>
		<p>Les fichiers de correction sont signalés par un chapeau rouge. Vous pouvez les télécharger, consulter nos correctifs et commentaires, et corriger en conséquence.</p>
		<p>Si vous avez accidentellement téléchargé le mauvais fichier, veuillez en informer immédiatement votre tuteur et il le supprimera.</p>
	`,
	el: `
		<p>Μπορείς να χρησιμοποιήσεις το κουμπί "Μεταφόρτωση" για να υποβάλεις φωτογραφίες ή αρχεία της δουλειάς σου για κάθε μια από τις παρακάτω εργασίες. Μπορείς να υποβάλεις περισσότερα από ένα αρχεία ανά
		εργασία μαθήματος εάν είναι απαραίτητο. Τα υποβληθέντα αρχεία θα αξιολογηθούν από την ομάδα καθηγητών μας και θα βαθμολογηθούν με ένα γράμμα.</p>
		<ul>
			<li>A σημαίνει ότι η εργασία είναι πολύ καλή χωρίς ή σχεδόν χωρίς κανένα λάθος. Σε αυτήν την περίπτωση, η δασκάλα συνήθως δεν ανεβάζει διορθώσεις.</li>
			<li>B σημαίνει καλή. Υπάρχουν λάθη αλλά όχι κρίσιμα. Ωστόσο, θα πρέπει να τα διορθώσεις και, εκτός εάν ζητήσει κάτι άλλο η δασκάλα, η διόρθωση θα πρέπει να ληφθεί υπόψη για τις επόμενες εργασίες.</li>
			<li>C σημαίνει ότι τα λάθη είναι κρίσιμα. Πρέπει να διορθωθούν και στη συνέχεια να υποβληθούν ξανά οι έργασίες.</li>
			<li>D σημαίνει ότι η εργασία υποβλήθηκε εντελώς εσφαλμένα ή παρεξηγήθηκε το ζητούμενο και πρέπει να ξανα-υποβληθεί από τη αρχή, ενδεχομένως μετά από συνεννόηση με τη δασκάλα.</li>
		</ul>
		<p>Τα αρχεία διόρθωσης σημειώνονται με κόκκινο. Μπορείς να τα κατεβάσεις, να δείς τις διορθώσεις και τα σχόλιά μας και να τα διορθώσεις κατά το ζητούμενο.</p>
		<p>Εάν κατά λάθος ανεβάσεις κάποιο λάθος αρχείο, ενημέρωσε αμέσως τη δασκάλα σου και θα το διαγράψει.</p>
	`,
	he: `
		<p>אתה יכול להשתמש בלחצן "העלה" כדי להעלות תמונות או קבצים של העבודה שלך עבור המשימות הבאות. ניתן להעלות יותר מקובץ אחד לכל מטלה במידת הצורך. הקבצים שהוגשו יוערכו על ידי צוות המורים שלנו ויקבלו ציון במכתב.</p>
		<ul>
			<li>A אומר שהמשימה טובה מאוד ללא שגיאות או כמעט ללא שגיאות. במקרה זה, המורה בדרך כלל אינו מעלה תיקונים.</li>
			<li>B פירושו טוב. יש כמה שגיאות אבל לא קריטיות. עם זאת, עליך לתקן אותם, אלא אם כן הוראה אחרת מהמורה, יש לציין את התיקון עבור המטלות הבאות.</li>
			<li>C אומר שהשגיאות הן קריטיות. יש לתקן אותם ולהגיש שוב את המשימה.</li>
			<li>D פירושו שהמשימה הוגשה בצורה שגויה או הובנה בצורה לא נכונה ולכן יש לבצע אותה מחדש ולהגיש אותה מחדש, אולי לאחר התייעצות עם המורה.</li>
		</ul>
		<p>קבצי תיקון מסומנים באדום. אתה יכול להוריד אותם, לראות את התיקונים והמשוב שלנו ולתקן אותם בהתאם.</p>
		<p>אם העלית בטעות את הקובץ הלא נכון, אנא הודע למורה שלך מיד והוא ימחק אותו.</p>
	`,
};

export default marksGuide;

